import React from "react"
import styled from "styled-components"

import { maxD } from "../../../assets/styles/helpers/devices"

const SignatureContent = styled.footer`
  padding: var(--padding-desktop, 2.5rem 2rem);

  @media ${maxD.mobileM} {
    padding: var(--padding-mobile, 2.5rem 1.5rem);
  }

  @media ${maxD.mobileS} {
    padding: 2.5rem 0;
  }
`

const AuthorName = styled.h3`
  font-size: 1.65rem;
  padding-bottom: 0.4rem;
`

const AuthorPosition = styled.p`
  @media ${maxD.laptopL} {
    font-size: 0.9rem;
  }
`

export const Signature = ({ name, position, style, nameStyle }) => (
  <SignatureContent style={style}>
    {name && <AuthorName style={nameStyle}>{name}</AuthorName>}
    {position && (
      <AuthorPosition
        dangerouslySetInnerHTML={{ __html: position }}
      ></AuthorPosition>
    )}
  </SignatureContent>
)
